@charset "utf-8";


//@use "custom.scss";

@import "minimal-mistakes/skins/default"; // skin
@import "minimal-mistakes"; // main partials

$primary-color: rgb(222, 193, 89);



h1, h2, h3, h4 {
    font-family: 'Baskerville', "Roboto", "Segoe UI", "Helvetica Neue", "Lucida Grande", Arial, sans-serif;
}

h2 {
    padding-top: 6.6vmax;
    margin: 2rem 0;
    text-align: center;
    font-size: calc((2.8 - 1) * 1.2vh + 1rem);
    line-height: calc(1.4 * (1 + (1 - 2.8)/25));
    letter-spacing: 0em;
    font-weight: 500;
}


.page__content  {
    p {
        line-height: 1.8;
        font-size: calc((1.2 - 1) * 1.2vh + 1rem);
        font-weight: 300;
    }

    li {
        line-height: 1.8;
        font-size: calc((1.2 - 1) * 1.2vh + 1rem);
        font-weight: 300;
   }

    h2 {
        border-bottom: 0;    
    }

    h4 {
        padding-top: 0.5rem;
        padding-bottom: 3rem;
        font-size: calc((1.6 - 1) * 1.2vh + 1rem);
        text-align: center;
    }
}


.masthead {
    a {
            color: $primary-color;

    }
}

blockquote {
    text-align: center;
}

@font-face {
    font-family: 'Baskerville';
    src: url(../baskvl.ttf) format('truetype');
}

body, #site-nav {
    background-color: #fbfbf9;
}

.btn--primary {
    padding: 20px 30px;
    color: $primary-color;
    background-color: #383838;
    font-weight: 300;
    font-size: 1.1rem;
    border-radius: 0.4rem;

    margin-bottom: 4rem;

    &:visited {
        background-color: #383838;
    }

    &:hover, &:visited {
        color: $primary-color;
    }
}

.site-logo img {
    max-height: 80px;
}

.footerFlex {
    display: flex;
    align-items: baseline;

    .page__footer-follow {
        margin-left: auto;
    }
}



//.btn--primary:hover { background-color: #595f64; color: #fff; }
